<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>
      Détails d'une fiche groupe : {{ contactGroupFormSheet ? resolveAttrValue(contactGroupFormSheet.camping, 'name') : '-' }}
    </v-card-title>

    <div class="clear"></div>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <h4 class="mb-5">
              Informations fiche groupe
            </h4>

            <v-select
              v-model="entityData.project"
              :items="projects"
              label="Projet"
              multiple
              chips
              outlined
            ></v-select>

            <br>

            <v-select
              v-model="entityData.nbPersons"
              :items="nbPersons"
              label="Participants"
              multiple
              chips
              outlined
            ></v-select>

            <br>

            <v-select
              v-model="entityData.nbPersonPerAccommodation"
              :items="nbPersonPerAccommodation"
              label="Personnes par hébergement"
              multiple
              chips
              outlined
            ></v-select>

            <br>

            <v-select
              v-model="entityData.cateringType"
              :items="cateringTypes"
              label="Restauration"
              multiple
              chips
              outlined
            ></v-select>

            <br>

            <v-select
              v-model="entityData.neededServices"
              :items="neededServices"
              label="Services"
              multiple
              chips
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'contactGroupFormSheetList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      projects: [
        {
          text: 'Loisirs/Tourisme',
          value: 'leisure_tourism'
        },
        {
          text: 'Mariage/Réunion familiale',
          value: 'wedding_family_reunion'
        },
        {
          text: 'Séminaire/Réunion d\'entreprise',
          value: 'seminar_business_meeting'
        }
      ],
      nbPersons: [
        {
          text: '10 à 20 personnes',
          value: '10_to_20'
        },
        {
          text: '20 à 50 personnes',
          value: '20_to_50'
        },
        {
          text: '50 à 80 personnes',
          value: '50_to_80'
        },
        {
          text: '80 personnes et +',
          value: '80_and_more'
        }
      ],
      nbPersonPerAccommodation: [
        {
          text: '2 personnes',
          value: '10_to_20'
        },
        {
          text: '4 personnes',
          value: '20_to_50'
        },
        {
          text: '6 personnes',
          value: '50_to_80'
        }
      ],
      cateringTypes: [
        {
          text: 'Pas de restauration',
          value: 'none'
        },
        {
          text: 'Restauration sur place',
          value: 'on_site_catering'
        },
        {
          text: 'Petit-Déjeuner',
          value: 'breakfast'
        },
        {
          text: 'Brunch',
          value: 'brunch'
        },
        {
          text: 'Demi-pension',
          value: 'half_board_meal'
        },
        {
          text: 'Pension complète',
          value: 'full_board_meal'
        },
        {
          text: 'Paniers repas',
          value: 'packed_lunches'
        }
      ],
      neededServices: [
        {
          text: 'Location de draps',
          value: 'sheets_rental'
        },
        {
          text: 'Location linge de toilette',
          value: 'bathroom_linen_rental'
        },
        {
          text: 'Service hôtelier (lits faits, linge et produits de toilette)',
          value: 'hotel_service'
        },
        {
          text: 'Location cuisine équipée',
          value: 'equipped_kitchen_rental'
        },
        {
          text: 'Location de salle',
          value: 'room_rental'
        },
        {
          text: 'Projection vidéo',
          value: 'video_projection'
        },
        {
          text: 'Sonorisation/éclairage',
          value: 'sound_lighting'
        }
      ],
      contactGroupFormSheet: null,
      entityData: {
        camping: null,
        project: [],
        nbPersons: [],
        nbPersonPerAccommodation: [],
        cateringType: [],
        neededServices: []
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  created () {
    this.getContactGroupFormSheet()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putContactGroupFormSheet()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getContactGroupFormSheet () {
      const contactGroupFormSheetId = this.$route.params.contactGroupFormSheetId

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/contact/group-form-sheets/' + contactGroupFormSheetId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.contactGroupFormSheet = response.data
            this.entityData = this.contactGroupFormSheet

            this.valid = true
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putContactGroupFormSheet () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      Vue.prototype.$http
        .put(config.apiUrl + '/api/contact/group-form-sheets/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.contactGroupFormSheet = response.data

            this.snackbar = true
            this.successMessage = 'Fiche groupe mise à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    resolveAttrValue (data, attr) {
      const translations = data.translations

      if (translations.fr) {
        return translations.fr[attr]
      }

      return '-'
    }
  }
}
</script>

